<template>
	<!-- <v-sheet class="recurring-main-listing" style="height: calc(87vh - 237px)"> -->
	<div>
		<template>
			<v-layout class="">
				<v-flex class="py-0 d-flex align-center">
					<v-spacer></v-spacer>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						v-on:click="createOrUpdateHostingdata"
						class="text-white my-auto mb-2"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
				</v-flex>
			</v-layout>
			<div class="overflow-y" style="height: calc(100vh - 395px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th class="p-2 light-blue-bg custome-table">Sr.No.</th>
							<th class="p-2 light-blue-bg custome-table">Type</th>
							<th class="p-2 light-blue-bg custome-table">Website Url</th>
							<th class="p-2 light-blue-bg custome-table">Server</th>
							<th class="p-2 light-blue-bg custome-table">Service Provider</th>
							<th class="p-2 light-blue-bg custome-table">Cpanel/Server URL</th>
							<th class="p-2 light-blue-bg custome-table">Duration</th>
							<th class="p-2 light-blue-bg custome-table">Start Date</th>
							<th class="p-2 light-blue-bg custome-table">End Date</th>
							<th class="p-2 light-blue-bg custome-table">Remark</th>
							<!-- <th class="p-2 light-blue-bg custome-table">Type</th> -->
						</tr>
					</thead>
					<tbody v-if="customerHosting && customerHosting.length">
						<tr
							v-for="(row, index) in customerHosting"
							:key="index"
							v-on:click="createOrUpdateHostingdata(row)"
						>
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue v-if="false" :object="row" object-key="id" label="sr"></ShowValue>
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<Chip
									v-if="row.type == 'bthrust'"
									class="text-capitalize"
									:text="row.type"
									color="green"
									text-color=""
									outlined
								></Chip>
								<Chip
									v-if="row.type == 'client'"
									class="text-capitalize"
									:text="row.type"
									color="red"
									text-color=""
									outlined
								></Chip>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.domain">
									{{ row.domain }}
								</span>
								<em class="text-muted" v-else>no server</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.server_name">
									{{ mod_string(row.server_name, "-") }}
								</span>
								<em class="text-muted" v-else>no server</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.service_provider"> {{ row.service_provider }} </span>
								<em class="text-muted" v-else>no service provider</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" style="max-width: 35rem">
								<div v-if="row && row.cpanal_url">{{ row.cpanal_url }}</div>
								<em class="text-muted" v-else>no cpanel url</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.duration"> {{ row.duration }}(Month) </span>
								<em class="text-muted" v-else>no duration</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.start_date">
									{{ formatDate(row.start_date) }}
								</span>
								<em class="text-muted" v-else>no start date</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.end_date">
									{{ formatDate(row.end_date) }}
								</span>
								<em class="text-muted" v-else>no end date</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" style="max-width: 25rem">
								<span v-if="row && row.remark">
									{{ row.remark }}
								</span>
								<em class="text-muted" v-else>no remark</em>
							</td>
							<!-- <td class="p-2 border-top-light-grey custome-table-td"> -->
							<span v-if="project && project.seo_type && false">
								<Chip
									class="text-capitalize"
									:text="project.seo_type"
									color="green"
									text-color=""
									outlined
								></Chip>
							</span>
							<!--	</td> -->
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="7">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no hosting at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</template>
		<CreateHosting
			v-if="hostingDialog"
			:hosting-dialog="hostingDialog"
			v-on:close="hostingDialog = false"
			:related-id="projectId"
			:service-id="serviceData.id"
			:hosting-data="serviceData"
			v-on:success="hostingDialog = false"
		>
		</CreateHosting>
	</div>
	<!-- </v-sheet> -->
</template>
<style>
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/* border-bottom: solid 1px #bcc0c9 !important; */
}

.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import { GET } from "@/core/services/store/request.module";
/* import Dialog from "@/view/components/Dialog"; */
import CreateHosting from "@/view/module/project/Create-Hosting.vue";
import MainMixin from "@/core/mixins/main.mixin";

export default {
	name: "Seo-report-listing",
	title: "Listing Invoice",
	mixins: [MainMixin],
	data() {
		return {
			pageTitle: "Seo-report",
			pageBreadcrumbs: [{ text: "Seo-report", disabled: true }],
			endpoint: "seo-report",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			customerServiceId: 0,
			hostingDialog: false,
			seoReport: [],
			serviceData: {},
			type: "Client",
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		clientData: {
			type: Boolean,
			default: false,
		},
		customerHosting: {
			type: Array,
		},
		project: {
			type: Object,
		},
		projectId: {
			type: Number,
			default: 0,
		},
		contractId: {
			type: Number,
			default: 0,
		},
		seoId: {
			type: Number,
			default: 0,
		},
	},
	/* watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	}, */
	methods: {
		createOrUpdateHostingdata(row) {
			if (row.id > 0) {
				this.getHosting(row.id);
				this.type = row.type || "Client";
			} else {
				this.serviceData = {};
				this.hostingDialog = true;
			}
		},
		getHosting(id) {
			this.hostingDialog = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `hosting/${id}`,
				})
				.then((data) => {
					_this.serviceData = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getSeo(page) {
			/* let status = this.status; */
			/* 	if (status == "checked-out" && this.status_filter) {
				status = this.status_filter;
			} */
			ApiService.query(`seo-report`, { page: page, search: this.DataSearch })
				.then(({ data }) => {
					this.seoReport = data.tbody;
					this.showing_string = data.showing_string;
					this.total_page = data.total_page;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		ShowValue,
		Chip,
		CreateHosting,
		/* 	ProjectSoeUrl,*/
		/* 	Dialog,  */
	},
	mounted() {
		this.getSeo();

		/* console.log(this.showing_string,'sjdfshgf.fhsjhdajkshdjas')
		this.$store.commit(SET_SHOWING_STRING, this.showing_string); */
	},
};
</script>
