<template>
	<Dialog :dialog="updatedDialog" :dialog-width="900" @close="$emit('close')">
		<template v-slot:title
			><!-- Client -->
			SSL
		</template>
		<template v-slot:body>
			<v-sheet id="lead">
				<v-form
					ref="extendCheckoutForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="px-3">
						<!--  <div class="form-grp-title d-flex">
                            <v-row class="px-4">
                                <v-col lg="4" cols="12" class="my-auto px-0"> Type</v-col>
                                <v-col lg="8" class="my-auto py-0">
                                    <v-radio-group v-model="createdData.type" row hide-details class="mt-0">
                                        <v-radio label="Client" value="client"></v-radio>
                                        <v-radio label="Bthrust" value="bthrust"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </div>  -->
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Service Provider</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0 position-relative">
								<v-autocomplete
									:items="getServiceProviders"
									item-text="text"
									item-value="value"
									hide-details
									outlined
									placeholder="Service Provider"
									v-model="createdData.service_provider"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<!--   <v-row class="mb-3 px-4">
                            <v-col lg="4" cols="12" class="my-auto py-0">
                                <label class="input-label">Cpanel/Server URL</label>
                            </v-col>
                            <v-col lg="8" class="my-auto py-0">
                                <v-text-field outlined hide-details placeholder="Cpanel/Server URL" v-model="createdData.cpanal_url">
                                </v-text-field>
                            </v-col>
                        </v-row> -->
						<v-row class="px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">SSL Period</label>
							</v-col>
							<v-col lg="2" class="my-auto py-0"
								><label class="input-label required">Duration</label></v-col
							>
							<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
							<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
							<v-col lg="2" class="my-auto py-0">
								<v-autocomplete
									:items="months"
									item-text="text"
									item-value="value"
									hide-details
									outlined
									placeholder="Month"
									v-model="createdData.duration"
									v-on:change="dateFormated(createdData, 'hosting')"
									class="mt-3"
									:rules="[vrules.required(createdData.duration, 'Duration')]"
									:class="{
										required: !createdData.duration,
									}"
								>
								</v-autocomplete>
							</v-col>
							<v-col lg="3" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="Start Date"
									v-model="createdData.start_date"
									v-on:change="dateFormated(createdData, 'hosting')"
								>
								</DatePicker>
							</v-col>
							<v-col lg="3" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									readonly
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="End Date"
									v-model="createdData.end_date"
								></DatePicker>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Web Url</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-text-field
													outlined
													hide-details
													placeholder="Web Url"
													v-model="createdData.domain_name"
													:rules="[vrules.validUrl(createdData.domain_name, 'Domain', true)]"
												/>
											</v-col>
										</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Remark </label>
							</v-col>

							<v-col lg="8" class="my-auto py-0">
								<TextAreaInput
									:rows="4"
									dense
									ref="comment"
									hide-details
									v-model="createdData.remark"
									placeholder="Enter Remark..."
									:disabled="pageLoading"
									:loading="pageLoading"
									:word-limit="200"
								></TextAreaInput>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				:disabled="pageLoading || !formValid"
				tile
				color="blue darken-4"
				class="text-white my-auto mt-3"
				v-on:click="update_or_create"
				>Save</v-btn
			>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				class="my-auto mt-3"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { GET, POST, PUT } from "@/core/services/store/request.module";
import Dialog from "@/view/components/Dialog";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
import MomentJS from "moment-timezone";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { HostingEventBus } from "@/core/lib/hosting.lib";
/* import TextInput from "@/view/components/TextInput"; */
import MainMixin from "@/core/mixins/main.mixin.js";
import { filter } from "lodash";
export default {
	mixins: [MainMixin],
	name: "extend-checkout-template",
	title: "Extend Checkout Template",
	props: {
		updatedDialog: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Object,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		serviceId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			serviceProviderDrawer: false,
			createdData: {
				service_provider: null,
				/*server : null, */
				type: "client",
				/*  cpanal_url: null, */
				duration: "12",
				projectId: 0,
				start_date: null,
				end_date: null,
				remark: null,
			},
			months: [
				{ value: "1", text: "1" },
				{ value: "2", text: "2" },
				{ value: "3", text: "3" },
				{ value: "4", text: "4" },
				{ value: "5", text: "5" },
				{ value: "6", text: "6" },
				{ value: "7", text: "7" },
				{ value: "8", text: "8" },
				{ value: "9", text: "9" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" },
				{ value: "13", text: "13" },
				{ value: "14", text: "14" },
				{ value: "15", text: "15" },
				{ value: "16", text: "16" },
				{ value: "17", text: "17" },
				{ value: "18", text: "18" },
				{ value: "19", text: "19" },
				{ value: "20", text: "20" },
				{ value: "21", text: "21" },
				{ value: "22", text: "22" },
				{ value: "23", text: "23" },
				{ value: "24", text: "24" },
			],
			serverProvider: [],
			serverWeb: [],
		};
	},
	watch: {
		updatedData: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.createdData.type = param.type ? param.type : "client";
					this.createdData.service_provider = param.service_provider ? param.service_provider : null;
					/* this.createdData.cpanal_url = param && param.cpanal_url ? param.cpanal_url : null; */
					this.createdData.duration =
						typeof param.duration == "number" ? param.duration.toString() : param.duration;
					this.createdData.start_date = param.start_date ? param.start_date : null;
					this.createdData.end_date = param.end_date ? param.end_date : null;
					this.createdData.remark = param.remark ? param.remark : null;
					this.createdData.domain_name = param.domain_name ? param.domain_name : null;

				}
			},
		},
	},
	components: {
		Dialog,
		/*        TextInput, */
		TextAreaInput,
		DatePicker,
	},

	methods: {
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["extendCheckoutForm"]);

			this.$refs["extendCheckoutForm"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["extendCheckoutForm"].validate()) {
				return false;
			}
			this.createdData.projectId = this.relatedId;
			let requestTYPE = POST;
			let requestURL = "ssl";
			let messageDyn = "Created";
			if (this.serviceId) {
				requestURL = `ssl/${this.serviceId}`;
				requestTYPE = PUT;
				messageDyn = "Updated";
			}

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: this.createdData,
				})
				.then(() => {
					HostingEventBus.$emit("refresh", true);
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! SSL " + messageDyn + " Successfully." },
						this.$emit("success", true),
					]);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "customer-setting",
				})
				.then((data) => {
					_this.serverProvider = data.serverProvider;
					_this.serverWeb = data.server;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		dateFormated(data, index) {
			if (data.start_date && index == "hosting") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				date.subtract(1, "days");
				this.createdData.end_date = date.format("YYYY-MM-DD");
			}
		},
	},
	mounted() {
		this.getSettings();
	},
	computed: {
		...mapGetters(["errors"]),
		getServiceProviders() {
			let type = this.createdData && this.createdData.type == "client" ? "client" : "company";
			return filter(this.serverProvider, (row) => row.is_ssl && row.type == type);
		},
	},
};
</script>
