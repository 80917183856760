<template>
	<div>
		<v-navigation-drawer
			class="productUnitDrawer"
			v-model="drawer"
			app
			stateless
			temporary
			right
			width="480px"
			content-class="nav-separation-from-modal"
		>
			<v-card class="" tile elevation="0">
				<v-card-title class="flex py-3 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">{{ title }}</div>
					<v-spacer></v-spacer>
					<v-btn tile depresed color="white--text" class="blue darken-4 mr-2" @click="addSetting">
						Save
					</v-btn>
					<v-btn tile depresed class="darken-4" v-on:click="$emit('close', true)"> Close </v-btn>
				</v-card-title>
				<v-card-text class="d-flex px-4">
					<v-row class="mt-2">
						<v-col
							md="12"
							cols="12"
							class="my-auto py-0 pr-12 mb-2 position-relative service-provider border rounded bg-light"
							v-for="(data, index) in create_setting"
							:key="index"
						>
							<v-radio-group hide-details dense v-model="create_setting[index].provider_type">
								<v-radio
									v-for="p in provider_types"
									:key="p.title + p.value"
									:label="p.title"
									:value="p.value"
									@change="setProviderType(index, p.value)"
								>
									<template #label> </template>
								</v-radio>
							</v-radio-group>

							<template v-if="create_setting[index].provider_type == '1'">
								<v-row>
									<v-col md="3">
										<v-checkbox
											v-model="create_setting[index].services.seo"
											:false-value="0"
											:true-value="1"
											label="SEO"
											dense
											hide-details
										></v-checkbox>
									</v-col>
									<v-col md="3">
										<v-checkbox
											v-model="create_setting[index].services.domain"
											:false-value="0"
											:true-value="1"
											label="Domain"
											hide-details
											dense
										></v-checkbox>
									</v-col>
									<v-col md="3">
										<v-checkbox
											v-model="create_setting[index].services.hosting"
											:false-value="0"
											:true-value="1"
											label="Hosting"
											hide-details
											dense
										></v-checkbox>
									</v-col>
								</v-row>
							</template>

							<TextInput
								style="margin-bottom: 8px !important"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="create_setting[index].text"
								placeholder="Service Provider"
							>
							</TextInput>

							<template v-if="data.id">
								<v-icon
									class="red--text cursor-pointer"
									style="position: absolute; top: 14px; right: 14px"
									v-on:click="deletefield(index)"
									>mdi-delete
								</v-icon>
							</template>
							<template v-else>
								<v-icon
									class="red--text cursor-pointer"
									style="position: absolute; top: 14px; right: 14px"
									v-on:click="deletefield(index)"
									>mdi-delete
								</v-icon>
							</template>
						</v-col>
						<v-col>
							<v-btn tile depressed color="blue darken-4" class="white--text" v-on:click="addField">
								<v-icon>mdi-plus</v-icon>
								Add
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-navigation-drawer>
	</div>
</template>

<style>
.nav-separation-from-modal {
	z-index: 9999 !important;
}
</style>

<script>
import TextInput from "@/view/components/TextInput";
// import SelectInput from "@/view/components/SelectInput";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "Create-Service-Provider",
	props: {
		title: {
			type: String,
			default: null,
		},
		typeId: {
			default: 0,
		},
		marginTop: {
			type: Boolean,
			default: false,
		},
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pageLoading: false,
			val: null,
			create_setting: [
				{
					text: null,
					provider_type: "1",
					services: {
						seo: 1,
						hosting: 0,
						domain: 0,
					},
					status_color: "green", // [ company - green  ---  client - red ]
				},
			],

			provider_types: [
				{
					title: "Company",
					value: "1",
				},
				{
					title: "Client",
					value: "2",
				},
			],
			services_array: [
				{
					title: "SEO",
					value: "seo",
				},
				{
					title: "Hosting",
					value: "hosting",
				},
				{
					title: "Domain",
					value: "domain",
				},
			],
		};
	},
	methods: {
		addField() {
			this.create_setting.push({
				text: null,
				provider_type: "1",
				services: {
					seo: 1,
					hosting: 0,
					domain: 0,
				},
				status_color: "green",
			});
		},
		deletefield(index) {
			this.create_setting.splice(index, 1);
		},
		setProviderType(index, p_type) {
			if (p_type == "1") {
				this.create_setting[index].services = {
					seo: 1,
					hosting: 0,
					domain: 0,
				};
			} else {
				this.create_setting[index].services = null;
			}
		},
		addSetting() {
			this.$store
				.dispatch(POST, {
					url: "lead-setting-update",
					data: {
						type: this.typeId,
						status: this.create_setting,
					},
				})
				.then(() => {
					this.$emit("success");
					// this.getSetting();
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Setting updated successfully.",
						},
					]);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-setting/${this.typeId}`,
				})
				.then((data) => {
					if (data && Array.isArray(data) && data.length) {
						let _data = [];
						data.forEach((p) => {
							let obj = { ...p, services: { seo: p.seo, domain: p.domain, hosting: p.hosting } };
							_data.push(obj);
						});
						this.create_setting = [..._data];
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		deleteSetting(id) {
			this.$store
				.dispatch(DELETE, {
					url: `lead-setting/${id}`,
				})
				.then(() => {
					this.getSetting();
					// this.$emit("success");
					this.$emit("delete");
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	components: {
		TextInput,
		// SelectInput,
	},
	mounted() {
		this.getSetting();
	},
};
</script>
