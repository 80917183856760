<template>
	<div>
		<template>
			<div class="overflow-y" style="max-height: calc(100vh - 395px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<!-- <th class="p-2 light-blue-bg custome-table">Action</th> -->
							<th class="p-2 light-blue-bg custome-table">Invoice</th>
							<th class="p-2 light-blue-bg custome-table">Customer Details</th>
							<th class="p-2 light-blue-bg custome-table">Invoice Date</th>
							<th class="p-2 light-blue-bg custome-table">Add / Due date</th>
							<th class="p-2 light-blue-bg custome-table">Payable / Paid / Balance</th>
							<!-- <th  class="p-2 light-blue-bg custome-table">Paid Amount</th> -->
							<th class="p-2 light-blue-bg custome-table">Service Type</th>
							<th class="p-2 light-blue-bg custome-table">Extension / Invoice Period</th>
							<th class="p-2 light-blue-bg custome-table">Status</th>
							<th class="p-2 light-blue-bg custome-table">Created At</th>
						</tr>
					</thead>
					<tbody v-if="invoiceData.tbody">
						<tr v-for="(row, index) in invoiceData.tbody" :key="index" v-on:click="detailInvoice(row.id)">
							<td class="p-2 border-top-light-grey custome-table-td">
								<div class="d-flex">
									<div class="mb-2">
										<div class="d-flex justify-space-between">
											<label class="font-size-14 mb-1 mr-5"><b> Project # : </b></label>
											<Chip :text="row.project_barcode" color="orange" v-if="row.project_barcode"></Chip>
										</div>
										<div class="mt-1 d-flex justify-space-between">
											<label class="font-size-14 mb-1 mr-4"><b> Quick Book Invoice # : </b></label>
											<Chip :text="row.barcode" v-if="row.barcode" color="cyan"></Chip>
											<span v-else>--</span>
										</div>
									</div>
									<!-- v-if="row.barcode" -->

									<div>
										<!-- <span v-if="projectData.status == 1 && projectData.renewed == 0" class="mb-2">
											<v-avatar color="red" size="35" class="mr-2">
												<span class="white--text fw-600" style="font-size: 18px"> R </span>
											</v-avatar>
										</span> -->
										<span v-if="projectData.renewed > 0" class="ml-2 mb-2">
											<v-avatar color="red" size="27">
												<span class="white--text fw-600"> R {{ projectData.renewed }}</span>
											</v-avatar>
										</span>
									</div>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div>
									<label class="font-size-14 mb-1 mr-1"
										><b>
											<v-icon size="18">mdi-pound</v-icon>
										</b></label
									>
									<span v-if="row.customer_barcode">{{ row.customer_barcode }} </span>
								</div>
								<!-- <div>
									<label class="font-size-14 mb-1 mr-1"
										><b>
											<v-icon size="18">mdi-account-circle</v-icon>
										</b></label
									>
									<span v-if="row.customer_company_name">{{ row.customer_company_name }}</span>
									<em v-else class="text-muted">no website</em>
								</div> -->
								<div>
									<label class="font-size-14 mb-1 mr-1"
										><b>
											<v-icon size="18">mdi-account</v-icon>
										</b></label
									>
									<span class="fw-500" v-if="row && row.attendies">
										<v-chip class="mb-1 mr-1" small v-for="(data, index) in row.attendies" :key="index">
											<v-avatar left small>
												<img v-if="data && data.image" :src="data.image" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>
											<span v-if="data && data.display_name">{{ data.display_name }}</span>
										</v-chip>
									</span>
									<em v-else class="text-muted">no sales person</em>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<template v-if="row.actual_date">
									<v-chip
										label
										color="red white--text"
										outlined
										text-color=""
										x-small
										v-if="row && row.actual_date && getDate(row) > 0"
									>
										<span class="fw-500" v-if="row && row.actual_date">
											{{ formatDate(row.actual_date) }}</span
										>
									</v-chip>
									<v-chip
										label
										color="green white--text"
										outlined
										text-color=""
										x-small
										v-if="row && row.actual_date && getDate(row) < 0"
									>
										<span class="fw-500" v-if="row && row.actual_date">
											{{ formatDate(row.actual_date) }}</span
										>
									</v-chip>
								</template>
								<span v-else>--</span>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div class="mb-1">
									<template>
										<v-chip
											label
											color="red white--text"
											outlined
											class="px-1"
											text-color=""
											x-small
											v-if="row && row.start_date"
										>
											<span class="fw-500" v-if="row && row.start_date"> {{ addDate(row.start_date) }}</span>
										</v-chip>
									</template>
								</div>
								<div>
									<v-chip
										label
										color="green white--text"
										outlined
										text-color=""
										class="px-1"
										x-small
										v-if="row && row.start_date"
									>
										<span class="fw-500" v-if="row && row.start_date"> {{ formatDate(row.start_date) }}</span>
									</v-chip>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row.payable_amount">
									<v-chip
										label
										color="orange white--text"
										outlined
										text-color=""
										class="px-1"
										x-small
										v-if="row && row.payable_amount"
									>
										<span class="fw-500" v-if="row && row.payable_amount">
											{{ formatMoney(row.payable_amount) }}</span
										>
									</v-chip>
									<v-chip
										label
										color="orange white--text"
										class="px-1"
										outlined
										text-color=""
										x-small
										v-else
									>
										<span class="fw-500"> $ 0.00</span>
									</v-chip>
									<div class="mt-1">
										<v-chip
											label
											color="green white--text"
											outlined
											text-color=""
											class="px-1"
											x-small
											v-if="row && row.paid_amount"
										>
											<span class="fw-500" v-if="row && row.paid_amount">
												{{ formatMoney(row.paid_amount) }}</span
											>
										</v-chip>
										<v-chip
											label
											color="green white--text"
											class="px-1"
											outlined
											text-color=""
											x-small
											v-else
										>
											<span class="fw-500"> $ 0.00</span>
										</v-chip>
									</div>
									<div class="mt-1">
										<v-chip
											label
											color="red white--text"
											outlined
											text-color=""
											x-small
											class="px-1"
											v-if="row && pendingBalance(row) > 0"
										>
											<span class="fw-500" v-if="row && pendingBalance(row) > 0">
												{{ pendingBalance(row) }}</span
											>
										</v-chip>
										<v-chip label color="red white--text" class="px-1" outlined text-color="" x-small v-else>
											<span class="fw-500"> {{ pendingBalance(row) }}</span>
										</v-chip>
									</div>
								</span>
								<span v-else> -- </span>
							</td>
							<!--  <td  class="p-2 border-top-light-grey custome-table-td">
                                    <span v-if="row.paid_amount">
                                        <v-chip label color="green white--text" x-small v-if="row && row.paid_amount">
                                            <span class="fw-500" v-if="row && row.paid_amount"> ${{ row.paid_amount }}</span>
                                        </v-chip>
                                    </span>
                                    <span v-else> -- </span>
                                   
                                </td> -->
							<td class="p-2 border-top-light-grey custome-table-td">
								<!-- <div v-if="billingType == 'single_billing'">
									<Chip text="Single Billing" color="blue" outlined text-color="" class="mr-1 mb-1"> </Chip>
								</div>
								<div v-if="billingType == 'multiple_billing'">
									<Chip text="Multiple Billing" color="blue" outlined text-color="" class="mr-1 mb-1">
									</Chip>
								</div> -->
								<!-- <pre>	{{ row }}</pre> -->
								<div class="service-type">
									<template v-if="row.seo > 0">
										<Chip text="SEO" color="blue" outlined text-color="" class="mr-1"> </Chip>
									</template>
									<template v-if="row.domain > 0">
										<Chip text="D" color="red" outlined text-color="" class="mr-1"> </Chip
									></template>
									<template v-if="row.web > 0"
										><Chip text="W" color="orange" outlined text-color="" class="mr-1"> </Chip
									></template>
									<template v-if="row.ssl > 0"
										><Chip text="SSL" color="green" outlined text-color="" class="mr-1"> </Chip
									></template>
									<template v-if="row.hostting > 0"
										><Chip text="H" color="blue-grey" outlined text-color="" class="mr-1"> </Chip
									></template>
									<template v-if="row.maintenance > 0"
										><Chip text="M" color="cyan" outlined text-color="" class="mr-1"> </Chip
									></template>
									<template v-if="row.software > 0">
										<Chip text="S" color="blue darken-4" outlined text-color="" class="mr-1"> </Chip>
									</template>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row.extend_count > 0">
									<v-avatar color="red" size="34" class="mb-2">
										<span class="white--text"> E {{ row.extend_count }}</span>
									</v-avatar>
									<div v-if="row.extend_time_period == 'month'">
										<v-chip class="mb-1 mr-1" label color="black" x-small outlined text-color="">
											{{ row.extend_duration }} (Months)
										</v-chip>
									</div>
									<div v-if="row.extend_time_period == 'day'">
										<v-chip class="mb-1 mr-1" label color="black" x-small outlined text-color="">
											{{ row.extend_duration }} (Days)
										</v-chip>
									</div>
								</span>
								<em v-else class="text-muted"> no extension</em>
								<div v-if="row.invoice_priod_month == 1">
									<Chip text="Monthly" color="blue" outlined text-color="" class="mr-1 mb-1"> </Chip>
								</div>
								<div v-if="row.invoice_priod_month == 3">
									<Chip text="Quarterly" color="orange" outlined text-color="" class="mr-1 mb-1"> </Chip>
								</div>
								<div v-if="row.invoice_priod_month == 6">
									<Chip text="Half Yearly" color="red" outlined text-color="" class="mr-1 mb-1"> </Chip>
								</div>
								<div v-if="row.invoice_priod_month == 12">
									<Chip text="Yearly" color="green" outlined text-color="" class="mr-1 mb-1"> </Chip>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div class="mb-2">
									<Chip :text="row.status" color="blue" v-if="row.status == 'Raised'"></Chip>
								</div>
								<div class="mb-2">
									<Chip
										text="Pending For Issuance"
										color="red"
										v-if="row.direct_type == 'pending-issue'"
										outlined
										text-color=""
									></Chip>
								</div>
								<div class="mb-2">
									<Chip
										text="Overdue For Issuance"
										color="#191970"
										v-if="row.direct_type == 'overdue-issue'"
										outlined
										text-color=""
									></Chip>
								</div>
								<div class="mb-2">
									<Chip text="Issued" color="#008080" v-if="row.actual_date" outlined text-color=""></Chip>
								</div>
								<div class="mb-2">
									<Chip :text="row.status" color="green" v-if="row.status == 'Paid'"></Chip>
								</div>
								<div class="mb-2">
									<Chip :text="row.status" color="orange" v-if="row.status == 'Partially Paid'"></Chip>
								</div>
								<div class="mb-2">
									<Chip :text="row.status" color="cyan" v-if="row.status == 'Overdue Payment'"></Chip>
								</div>

								<div class="mb-2">
									<Chip
										:text="row.status"
										color="red"
										v-if="row.status == 'Pending For Issuance'"
										outlined
										text-color=""
									></Chip>
								</div>
								<div class="mb-2">
									<Chip
										:text="row.status"
										class="px-2 fw-500"
										x-small
										color="cyan"
										v-if="row.status == 'Draft'"
									></Chip>
								</div>
								<div class="mb-2">
									<Chip
										:text="row.status"
										color="#191970"
										v-if="row.status == 'Overdue For Issuance'"
										outlined
										text-color=""
									></Chip>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<!-- <span v-if="row && row.added_at">
                                        <Chip
                                        class="text-capitalize"
                                        :text="row.added_at"
                                        color="green"
                                        text-color=""
                                        outlined
                                    ></Chip>
                                    </span>  -->
								<v-tooltip top content-class="custom-top-tooltip">
									<template #activator="{ on, attrs }">
										<span v-on="on" v-bind="attrs">
											<i class="fa-solid fa-clock-rotate-left"></i>
											<!-- <pre>{{ data }}</pre> -->
											<!-- 	<v-icon class="mdi-16px">mdi-clock-outline</v-icon> -->
											<Chip
												class="text-capitalize fw-500 px-1"
												small
												:text="row.added_at"
												color="green"
												text-color=""
												outlined
											></Chip>
										</span>
									</template>
									<span v-if="row && row.added_at_format">{{ formatDateTime(row.added_at_format) }}</span>
								</v-tooltip>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="7">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no Invoice at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</template>
		<!-- <CreateContact
			v-on:success="(updateDialog = false), getInvoice()"
			v-on:refress="(updateDialog = false), getInvoice()"
			v-on:close="updateDialog = false"
			:update-dialog="updateDialog"
			:contract-id="contactId"
			:customer-id="customerId"
			internal
		>
		</CreateContact> -->
		<Dialog :dialog="urlsDialog" v-if="urlsDialog" :dialog-width="1200">
			<template v-slot:title>Seo URL</template>
			<template v-slot:body>
				<ContractSoeUrl
					v-on:close="urlsDialog = false"
					:customer-url="customerService"
					:service-id="customerServiceId"
				>
				</ContractSoeUrl>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="urlsDialog = false"> Close </v-btn>
			</template>
		</Dialog>
		<Dialog v-if="deleteDialog" :dialog="deleteDialog" :dialog-width="710" content-class="testdata">
			<template v-slot:title>Delete Invoice <v-spacer></v-spacer> </template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<v-icon color="red" large>mdi-alert</v-icon>
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							Deleting is irreversible and will also delete all associated records.<br />Are you sure about
							deleting it?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					color="white--text"
					class="blue darken-4"
					v-on:click="deleteDialog = false"
				>
					Yes
				</v-btn>
				<v-btn class="close-btn" depressed tile v-on:click="extentionDialog = false"> No </v-btn>
			</template>
		</Dialog>
		<!-- <UpdateInvoice
			:customer-data-id="customerId"
			v-on:success="(UpdateInvoiceDialog = false), getInvoice()"
			:updated-data="singleData"
			v-on:close="UpdateInvoiceDialog = false"
			:update-invoice-dialog="UpdateInvoiceDialog"
			:invoice-count="invoiceData && invoiceData.tbody && invoiceData.tbody.length"
			:project-cost="project_cost"
		>
		</UpdateInvoice> -->
	</div>
</template>
<style>
.custome-table {
	background-color: #f3f6f9 !important;
	border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
import Chip from "@/view/components/Chip";
import ContractSoeUrl from "@/view/module/contract/Contract-Seo-Urls";
// import CreateContact from "@/view/module/contract/Create-Contract";
import { SET_SHOWING_STRING, SET_TOTAL_PAGE } from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { GET } from "@/core/services/store/request.module";
// import UpdateInvoice from "@/view/module/components/update-invoice";
/* import { toSafeInteger } from "lodash"; */
import Dialog from "@/view/components/Dialog";
import MomentJS from "moment-timezone";

export default {
	name: "Seo-report-listing",
	title: "Listing Invoice",
	data() {
		return {
			pageTitle: "Seo-report",
			pageBreadcrumbs: [{ text: "Seo-report", disabled: true }],
			endpoint: "seo-report",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			project_cost: null,
			customerServiceId: 0,
			urlsDialog: false,
			deleteDialog: false,
			listingStatus: [],
			invoiceData: [],
			updateDialog: false,
			contactId: 0,
			UpdateInvoiceDialog: false,
			page: 1,
			customerData: [],
			DataSearch: null,
			showing_string: null,
			singleData: {},
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		billingType: {
			type: String,
			default: null,
		},
		customerService: {
			type: Array,
		},
		contarct: {
			type: Object,
		},
		projectData: {
			type: Object,
		},
		contractId: {
			type: Number,
			default: 0,
		},
		seoId: {
			type: Number,
			default: 0,
		},
		internalId: {
			type: Number,
			default: 0,
		},
		type: {
			type: String,
			default: null,
		},
	},
	watch: {
		customerId: {
			handler(param) {
				if (param) {
					this.getInvoice();
				}
			},
		},
	},
	methods: {
		pendingBalance(data) {
			let result = data.payable_amount - data.paid_amount;
			if (+result) {
				return this.formatMoney(result);
			}
		},
		getDate(row) {
			let addDates = MomentJS(row.start_date, "YYYY-MM-DD").subtract(15, "days");
			let invoiceDate = MomentJS(row.actual_date, "YYYY-MM-DD");
			return invoiceDate.diff(addDates, "days");
		},
		getCustomerData() {
			this.$store
				.dispatch(GET, {
					url: `customer`,
				})
				.then((data) => {
					this.customerData = data.tbody;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		detailInvoice(id) {
			this.$router.push({
				name: "invoice-detail",
				params: { id },
				query: { t: new Date().getTime() },
			});
		},
		addDate(date) {
			let newDate = MomentJS(date, "YYYY-MM-DD").subtract(15, "days").format("YYYY-MM-DD");
			return this.formatDate(newDate);
		},
		routeToDetail(id) {
			this.$router.push({
				name: "project-detail",
				params: { id },
			});
		},
		getInvoice(page) {
			ApiService.query(`invoice`, {
				page: page,
				search: this.DataSearch,
				related_id: this.customerId,
				internal_id: this.internalId,
			})
				.then(({ data }) => {
					this.invoiceData = data;
					this.showing_string = data.showing_string;
					this.total_page = data.total_page;
					this.listingStatus = data.status;
					this.$store.commit(SET_SHOWING_STRING, this.showing_string);
					this.$store.commit(SET_TOTAL_PAGE, this.total_page);
					this.$emit("invoiceLength", this.invoiceData.tbody.length);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		Chip,
		ContractSoeUrl,
		// UpdateInvoice,
		Dialog,
		// CreateContact,
	},
	mounted() {
		this.getInvoice();
		this.getCustomerData();
	},
	computed: {
		...mapGetters(["tbody"]),
	},
};
</script>
