<template>
	<!-- <v-sheet class="recurring-main-listing" style="height: calc(87vh - 88px)"> -->
	<div>
		<template>
			<v-layout class="">
				<v-flex class="py-0 d-flex">
					<v-spacer></v-spacer>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						v-on:click="createOrUpdatedata"
						class="text-white my-auto mb-2"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
				</v-flex>
			</v-layout>
			<div class="overflow-y" style="max-height: calc(100vh - 395px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th class="p-2 light-blue-bg custome-table">Sr.No.</th>
							<th class="p-2 light-blue-bg custome-table">Type</th>
							<th class="p-2 light-blue-bg custome-table">Web Url</th>
							<th class="p-2 light-blue-bg custome-table">Service Provider</th>
							<th class="p-2 light-blue-bg custome-table">Duration</th>
							<th class="p-2 light-blue-bg custome-table">Start Date</th>
							<th class="p-2 light-blue-bg custome-table">End Date</th>
							<th class="p-2 light-blue-bg custome-table">Remark</th>
						</tr>
					</thead>
					<tbody v-if="customerSsl && customerSsl.length">
						<tr v-for="(row, index) in customerSsl" :key="index" v-on:click="createOrUpdatedata(row.id)">
							<td class="p-2 border-top-light-grey custome-table-td">
								<ShowValue v-if="false" :object="row" object-key="id" label="sr"></ShowValue>
								{{ index + 1 }}
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<Chip
									v-if="row.type == 'bthrust'"
									class="text-capitalize"
									:text="row.type"
									color="green"
									text-color=""
									outlined
								></Chip>
								<Chip
									v-if="row.type == 'client'"
									class="text-capitalize"
									:text="row.type"
									color="red"
									text-color=""
									outlined
								></Chip>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" style="max-width: 9rem">
								<span v-if="row && row.domain_name"> {{ row.domain_name }} </span>
								<em class="text-muted" v-else>no Web Url</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" style="max-width: 9rem">
								<span v-if="row && row.service_provider"> {{ row.service_provider }} </span>
								<em class="text-muted" v-else>no service provider</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.duration"> {{ row.duration }}(Month) </span>
								<em class="text-muted" v-else>no Month</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.start_date">
									{{ formatDate(row.start_date) }}
								</span>
								<em class="text-muted" v-else>no start date</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.end_date">
									{{ formatDate(row.end_date) }}
								</span>
								<em class="text-muted" v-else>no end date</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" style="max-width: 25rem">
								<span v-if="row && row.remark">
									{{ row.remark }}
								</span>
								<em class="text-muted" v-else>no remark</em>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="7">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no SSL at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</template>

		<Dialog :dialog="urlsDialog" :dialog-width="1200" v-if="false">
			<template v-slot:title>Seo URL</template>
			<template v-slot:body>
				<ProjectSoeUrl
					v-on:close="urlsDialog = false"
					:customer-url="customerService"
					:service-id="customerServiceId"
				>
				</ProjectSoeUrl>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="urlsDialog = false"> Close </v-btn>
			</template>
		</Dialog>
		<CreateSsl
			v-if="sslDialog"
			:updated-dialog="sslDialog"
			v-on:close="sslDialog = false"
			:related-id="projectId"
			:updated-data="serviceData"
			:service-id="serviceData.id"
			v-on:success="sslDialog = false"
		>
		</CreateSsl>
	</div>
	<!-- </v-sheet> -->
</template>
<style>
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/* border-bottom: solid 1px #bcc0c9 !important; */
}

.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
</style>
<script>
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import ProjectSoeUrl from "@/view/module/project/Project-Seo-Url.vue";
import Dialog from "@/view/components/Dialog";
import CreateSsl from "@/view/module/project/Create-Ssl.vue";
import { GET } from "@/core/services/store/request.module";

export default {
	name: "Seo-report-listing",
	title: "Listing Invoice",
	/* mixins: [ListingMixin], */
	data() {
		return {
			pageTitle: "Seo-report",
			pageBreadcrumbs: [{ text: "Seo-report", disabled: true }],
			endpoint: "seo-report",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			customerServiceId: 0,
			urlsDialog: false,
			sslDialog: false,
			seoReport: [],
			serviceData: {},
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		customerSsl: {
			type: Array,
		},
		project: {
			type: Object,
		},
		projectId: {
			type: Number,
			default: 0,
		},
		contractId: {
			type: Number,
			default: 0,
		},
	},
	/* watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	}, */
	methods: {
		createOrUpdatedata(id) {
			if (id > 0) {
				this.getData(id);
			} else {
				this.serviceData = {};
				this.sslDialog = true;
			}
		},
		getData(id) {
			this.sslDialog = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `ssl/${id}`,
				})
				.then((data) => {
					_this.serviceData = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
	components: {
		ShowValue,
		Chip,
		ProjectSoeUrl,
		Dialog,
		CreateSsl,
	},
	mounted() {
		/* console.log(this.showing_string,'sjdfshgf.fhsjhdajkshdjas')
		this.$store.commit(SET_SHOWING_STRING, this.showing_string); */
	},
};
</script>
