<template>
	<!-- <v-sheet class="recurring-main-listing" style="height: calc(87vh - 88px)"> -->
	<div>
		<div class="overflow-y" style="max-height: calc(100vh - 395px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-blue-bg custome-table">SR.No.</th>
						<th class="p-2 light-blue-bg custome-table">Keyword</th>
						<th class="p-2 light-blue-bg custome-table">Duration</th>
						<th class="p-2 light-blue-bg custome-table">Start Date</th>
						<th class="p-2 light-blue-bg custome-table">End Date</th>
						<th class="p-2 light-blue-bg custome-table">Extend Free Date</th>
						<th class="p-2 light-blue-bg custome-table">Remark</th>
						<th class="p-2 light-blue-bg custome-table">Type</th>
					</tr>
				</thead>
				<tbody v-if="customerService.length">
					<tr
						v-for="(row, index) in customerService"
						:key="index"
						v-on:click="detailUrls(row.pre_seo_id)"
					>
						<td class="p-2 border-top-light-grey custome-table-td">
							<ShowValue v-if="false" :object="row" object-key="id" label="sr"></ShowValue>
							{{ index + 1 }}
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<span v-if="row && row.keyword_monthly"> {{ row.keyword_monthly }} </span>
							<em class="text-muted" v-else>no keyword</em>
							+
							<span v-if="row && row.keyword_free"> {{ row.keyword_free }}(Free) </span>
							<em class="text-muted" v-else>no free</em>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<span v-if="row && row.total_time"> {{ row.total_time }}(Month) </span>
							<em class="text-muted" v-else>no Month</em>
							+
							<span v-if="row && row.time_free"> {{ row.time_free }}(Free) </span>
							<em class="text-muted" v-else>no free</em>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<span v-if="row && row.select_date">
								{{ formatDate(row.select_date) }}
							</span>
							<em class="text-muted" v-else>no start date</em>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<span v-if="row && row.total_time_end">
								{{ formatDate(row.total_time_end) }}
							</span>
							<em class="text-muted" v-else>no end date</em>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<span v-if="row && row.time_free_end">
								{{ formatDate(row.time_free_end) }}
							</span>
							<em class="text-muted" v-else>no extend free date</em>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<span v-if="row && row.remark">
								{{ row.remark }}
							</span>
							<em class="text-muted" v-else>no remark</em>
						</td>
						<td class="p-2 border-top-light-grey custome-table-td">
							<span v-if="project && project.seo_type">
								<Chip
									class="text-capitalize"
									:text="project.seo_type"
									color="green"
									text-color=""
									outlined
								></Chip>
							</span>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="7">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no file at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>

		<Dialog :dialog="urlsDialog" :dialog-width="1200" @close="urlsDialog = false">
			<template v-slot:title>Seo URL</template>
			<template v-slot:body>
				<div style="height: calc(100vh - 230px)">
					<ProjectSoeUrl
						v-on:close="urlsDialog = false"
						:customer-url="customerService"
						:service-id="customerServiceId"
					>
					</ProjectSoeUrl>
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="urlsDialog = false"> Close </v-btn>
			</template>
		</Dialog>
	</div>
	<!-- </v-sheet> -->
</template>
<style>
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/* border-bottom: solid 1px #bcc0c9 !important; */
}

.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import ProjectSoeUrl from "@/view/module/project/Project-Seo-Url.vue";
import Dialog from "@/view/components/Dialog";

export default {
	name: "Seo-report-listing",
	title: "Listing Invoice",
	/* mixins: [ListingMixin], */
	data() {
		return {
			pageTitle: "Seo-report",
			pageBreadcrumbs: [{ text: "Seo-report", disabled: true }],
			endpoint: "seo-report",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			customerServiceId: 0,
			urlsDialog: false,
			seoReport: [],
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		customerService: {
			type: Array,
		},
		project: {
			type: Object,
		},
		projectId: {
			type: Number,
			default: 0,
		},
		contractId: {
			type: Number,
			default: 0,
		},
		seoId: {
			type: Number,
			default: 0,
		},
	},
	/* watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	}, */
	methods: {
		detailUrls(id) {
			this.customerServiceId = id;
			this.urlsDialog = true;
		},
		getSeo(page) {
			/* let status = this.status; */
			/* 	if (status == "checked-out" && this.status_filter) {
				status = this.status_filter;
			} */
			ApiService.query(`seo-report`, { page: page, search: this.DataSearch })
				.then(({ data }) => {
					this.seoReport = data.tbody;
					this.showing_string = data.showing_string;
					this.total_page = data.total_page;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		ShowValue,
		Chip,
		ProjectSoeUrl,
		Dialog,
	},
	mounted() {
		this.getSeo();
		/* console.log(this.showing_string,'sjdfshgf.fhsjhdajkshdjas')
		this.$store.commit(SET_SHOWING_STRING, this.showing_string); */
	},
};
</script>
